/* Navbar */

.navbar {
  position: fixed;
  z-index: 10;
  width: 100%;
  background-color: black;
  transition-duration: 0.7s;
  text-align: center;
}

.transparency{
  background-color: transparent;
}

.transparency:hover{
  background-color: black;
} 

.nav-img {
  text-align: left;
  padding: 0px;
  padding-left: 20px;
  height: 3.7rem;
  width: 39rem;
}

.nav-links {
  color: white !important;
  font-family: "Poppins", sans-serif;
  text-shadow: 0px 0px 4px black;
}

.nav-links:hover {
  text-decoration: underline;
}


/* media */
@media screen and (max-width: 768px) {
  .nav-img {
    margin: 0;
    padding: 0;
    width: 16rem;
    height: 1.8rem;
  }
}