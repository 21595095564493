body {
  background-color: black;
}

/* Home */
.carousel-img {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

/* About */

.about-nav {
  margin-top: 40px;
}

.about-nav-link {
  color: #fff;
}

.about-nav-link:hover {
  color: rgb(201, 201, 201);
}

.section-title {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500; /*default 400*/
  text-align: center;
  padding-bottom: 1%;
}

.section {
  background-color: white;
  font-family: "Poppins", sans-serif;
  padding: 2% 5% 1%;
  font-size: 1.3rem;
}

.section-p {
  text-align: left;
}

.about-list {
  text-align: left;
  list-style-type: none;
  margin-right: 50%;
}

/*Map*/

.map-img {
  width: 100vw;
  height: 60vh;
  object-fit: cover;
}

/* Form */

.contact-form {
  margin-top: 3%;
  margin-bottom: 2%;
  color: white;
}

.contact-form h1 {
  font-family: "Roboto Condensed", sans-serif;
  margin-top: 15%;
  font-size: 5rem;
  font-weight: 700;
}


/* Media */
@media screen and (min-width: 992px) {
  .about-section {
    padding-left: 13%;
    padding-right: 13%;
  }

  .about-p {
    padding-left: 1%;
    padding-right: 1%;
  }

  .map-img {
    max-width: 98.9vw;
  }
}

@media screen and (max-width: 992px) {
  .about-section {
    font-size: small;
  }

  .about-list {
    width: 100%;
    margin-left: 0;
  }

  .contact-form h1 {
    font-size: 3em;
  }
}
